import { types } from "../types/type";

export const inputReducer = (state = {}, action) => {
  switch (action.type) {
    case types.AcumuladorAño:
      return {
        ...state,
        acumulador: {
          ...state.acumulador,
          año: action.payload.año,
        },
      };
    case types.AcumuladorMarca:
      return {
        ...state,
        acumulador: {
          ...state.acumulador,
          marca: action.payload.marca,
          brand_id: action.payload.id,
        },
      };
    case types.AcumuladorModelo:
      return {
        ...state,
        acumulador: {
          ...state.acumulador,
          modelo: action.payload.modelo,
          model_id: action.payload.id,
        },
      };
    case types.AcumuladorVersion:
      return {
        ...state,
        acumulador: {
          ...state.acumulador,
          version: action.payload.version,
          version_id: action.payload.id,
        },
      };

    case types.AcumuladorKilometros:
      return {
        ...state,
        acumulador: {
          ...state.acumulador,
          kilometros: action.payload.kilometros,
        },
      };
    case types.AcumuladorGNC:
      return {
        ...state,
        acumulador: {
          ...state.acumulador,
          gnc: action.payload.gnc,
        },
      };
    case types.GetYears:
      return {
        ...state,
        years: action.payload.years,
      };

    case types.GetBrands:
      return {
        ...state,
        brands: action.payload.brands,
      };
    case types.GetModels:
      return {
        ...state,
        models: action.payload.models,
      };
    case types.GetVersion:
      return {
        ...state,
        version: action.payload.versions,
      };

    case types.SendKms:
      return {
        ...state,
        valorKms: action.payload.valorKms,
      };

    case types.EsTablet:
      return {
        ...state,
        valor: action.payload.valor,
      };

    case types.EsCelular:
      return {
        ...state,
        valor: action.payload.valor,
      };

    case types.CloseKms:
      return {
        ...state,
        close: action.payload.close,
      };

    case types.Loading:
      return {
        ...state,
        telefono: action.payload.telefono,
        loading: action.payload.loading,
        status: action.payload.status,
      };

    case types.SetState:
      return {
        set: action.payload.set,
      };
    default:
      return state;
  }
};
