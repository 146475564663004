import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendKmsRedux,
  startAcumuladorVersion,
  startGetYears,
} from "../../actions/pasosCotizar";
import Hidden from "@mui/material/Hidden";

export const Version = () => {
  const { acumulador, version, valorKms } = useSelector(
    (state) => state.inputReducer
  );

  const dispatch = useDispatch();

  const [mostrarSelect, setmostrarSelect] = useState(false);

  const mostrarOpciones = () => {
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorVersion(undefined));
    dispatch(sendKmsRedux(undefined));
  };

  const seleccionarVersion = (id, name, years) => {
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorVersion(name, id));
    dispatch(startGetYears(years));
    if (valorKms !== undefined) {
      dispatch(sendKmsRedux(""));
    }
  };

  return (
    <>
      <Hidden mdDown>
        <div className="container-input-scroll">
          {acumulador !== undefined &&
          acumulador.modelo !== undefined &&
          acumulador.modelo !== "" ? (
            <>
              {acumulador.version !== undefined && acumulador.version !== "" ? (
                <div
                  className="btn-hecho animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  {acumulador.version.toLowerCase()}
                </div>
              ) : (
                <div
                  className="btn-activo animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  Version
                </div>
              )}
            </>
          ) : (
            <div className="btn-disabled">Version</div>
          )}
          {acumulador !== undefined &&
          acumulador.modelo !== undefined &&
          acumulador.modelo !== "" &&
          (acumulador.version === undefined || acumulador.version === "") ? (
            <>
              {version !== undefined ? (
                <div
                  className={
                    version.length < 8
                      ? "select-input-ajustable"
                      : "select-input"
                  }
                >
                  <>
                    {version.map((info) => (
                      <p
                        className="text-opciones animate__fadeInDownBig"
                        onClick={() =>
                          seleccionarVersion(info.id, info.name, info.years)
                        }
                        key={info.id}
                      >
                        {info.name.toLowerCase()}{" "}
                      </p>
                    ))}
                  </>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className="container-input-scroll">
          {acumulador !== undefined &&
          acumulador.modelo !== undefined &&
          acumulador.modelo !== "" ? (
            <>
              {acumulador.version !== undefined && acumulador.version !== "" ? (
                <div
                  className="btn-hecho"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  {acumulador.version}
                </div>
              ) : (
                <div
                  className="btn-activo"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  Version
                </div>
              )}
            </>
          ) : (
            <div
              className="btn-disabled"
              style={{ width: "160px", height: "33px", fontSize: "16px" }}
            >
              Version
            </div>
          )}
          {/* {mostrarSelect ? ( */}
          {mostrarSelect ||
          (acumulador !== undefined &&
            acumulador.modelo !== undefined &&
            acumulador.modelo !== "" &&
            acumulador.version === undefined) ||
          (acumulador !== undefined &&
            acumulador.modelo !== undefined &&
            acumulador.modelo !== "" &&
            acumulador.version === "") ? (
            <>
              {version !== undefined ? (
                <div
                  className={
                    version.length < 8
                      ? "select-input-ajustable"
                      : "select-input"
                  }
                >
                  <>
                    {version.map((info) => (
                      <p
                        className="text-opciones animate__fadeInDownBig"
                        onClick={() =>
                          seleccionarVersion(info.id, info.name, info.years)
                        }
                        key={info.id}
                      >
                        {info.name.toLowerCase()}{" "}
                      </p>
                    ))}
                  </>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </Hidden>
    </>
  );
};
