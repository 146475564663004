import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ModalCotizar } from "../ModalCotizar/ModalCotizar";
import axios from "axios";

export const BotonContinuarTablet = () => {
  const { acumulador, valorKms } = useSelector((state) => state.inputReducer);
  const [messageCotizacion, setMessageCotizacion] = useState(
    "Obteniendo rango ..."
  );

  const [open, setOpen] = React.useState(false);

  const getDataCotizacion = () => {
    axios
      .get(
        `https://infoauto-348420.rj.r.appspot.com/cotizacion?brand=${
          acumulador?.marca
        }&model=${acumulador?.modelo}&version=${
          acumulador?.version
        }&version_id=${acumulador?.version_id}&year=${
          acumulador?.año
        }&kms=${valorKms}&gnc=${acumulador?.gnc === "Si" ? "true" : "false"}`
      )
      .then((resp) => {
        setMessageCotizacion(resp.data.message);
      })
      .catch((e) => {
        console.log("ERROR", e.response.status);
      });
  };

  const enviarDatos = () => {
    setOpen(true);
    getDataCotizacion();
  };

  return (
    <>
      {valorKms !== undefined &&
      valorKms > 0 &&
      acumulador.gnc !== undefined &&
      acumulador.gnc !== "" ? (
        <div
          className="btn-continuar-activo-tablet"
          style={{ width: "100%", marginTop: "0px", marginBottom: "0px" }}
          onClick={enviarDatos}
        >
          Cotizar
        </div>
      ) : (
        <div
          className="btn-continuar-disabled-tablet"
          style={{ width: "100%", marginTop: "0px", marginBottom: "0px" }}
        >
          Cotizar
        </div>
      )}
      <ModalCotizar
        openModalManual={open}
        setopenModalManual={setOpen}
        messageCotizacion={messageCotizacion}
        setMessageCotizacion={setMessageCotizacion}
      />{" "}
    </>
  );
};
