import { Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { setState } from "../../actions/pasosCotizar";
import { useDispatch } from "react-redux";

export const ModalCotizar = ({
  openModalManual,
  setopenModalManual,
  messageCotizacion,
  setMessageCotizacion,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setState(true));
    setMessageCotizacion("Obteniendo rango ...");
    setopenModalManual(false);
  };

  return (
    <>
      <Modal
        open={openModalManual}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div className="containerModalCotizar">
            <p
              className="titleInicial"
              style={{
                marginBottom: "5px",
                marginTop: "5px",
                textAlign: "center",
              }}
            >
              {messageCotizacion}
            </p>
            {messageCotizacion !== "Obteniendo rango ..." ? (
              <button
                className="btn-cotizar-activo"
                type="submit"
                onClick={handleClose}
              >
                Cotizar otro auto
              </button>
            ) : (
              <CircularProgress />
            )}
          </div>
        </>
      </Modal>
    </>
  );
};
