import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrands,
  sendKmsRedux,
  startAcumuladorAño,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
  startGetBrands,
  startGetModels,
  startGetVersion,
} from "../../actions/pasosCotizar";
import Hidden from "@mui/material/Hidden";
import { isCelular, isTablet } from "../../actions/pasosTablet";
import "animate.css";

export const Año = () => {
  const dispatch = useDispatch();
  const { acumulador } = useSelector((state) => state.inputReducer);

  const [mostrarSelect, setmostrarSelect] = useState(false);

  const years = [
    2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012,
    2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
  ];

  const mostrarOpciones = () => {
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorAño(undefined));
    dispatch(startAcumuladorMarca(undefined));
    dispatch(startAcumuladorModelo(undefined));
    dispatch(startAcumuladorVersion(undefined));
    dispatch(sendKmsRedux(undefined));
    dispatch(startGetBrands([]));
    dispatch(startGetModels([]));
    dispatch(startGetVersion([]));
  };

  const seleccionarAño = (data) => {
    getBrands(dispatch, data);
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorAño(data));
  };

  const seleccionarAñoTablet = (data) => {
    getBrands(dispatch, data);
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorAño(data));
    dispatch(isTablet("tablet"));
  };

  const seleccionarAñoCelular = (data) => {
    getBrands(dispatch, data);
    setmostrarSelect(!mostrarSelect);
    dispatch(startAcumuladorAño(data));
    dispatch(isCelular("celular"));
  };

  return (
    <>
      <Hidden mdDown>
        <div className="container-input-scroll">
          <div
            className={
              acumulador !== undefined && acumulador.año !== undefined
                ? "btn-hecho animate__fadeIn"
                : "btn-activo animate__fadeIn"
            }
            onClick={mostrarOpciones}
          >
            {acumulador !== undefined && acumulador.año !== undefined
              ? acumulador.año
              : "Año"}
          </div>
          {acumulador === undefined || acumulador.año === undefined ? (
            <div className="select-input">
              {years !== undefined ? (
                <>
                  {years
                    .sort(function (a, b) {
                      return b - a;
                    })
                    .map((info, i) => (
                      <p
                        className="text-opciones animate__fadeInDownBig"
                        onClick={() => seleccionarAño(info)}
                        key={i}
                      >
                        {info}
                      </p>
                    ))}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className="container-input-scroll">
          <div
            className={
              acumulador !== undefined && acumulador.año !== ""
                ? "btn-hecho"
                : "btn-activo"
            }
            style={{ width: "160px", height: "33px", fontSize: "16px" }}
            onClick={mostrarOpciones}
          >
            {acumulador !== undefined && acumulador.año !== ""
              ? acumulador.año
              : "Año"}
          </div>
          {mostrarSelect ||
          acumulador === undefined ||
          acumulador.año === "" ? (
            <div className="select-input">
              {years !== undefined ? (
                <>
                  {years
                    .sort(function (a, b) {
                      return b - a;
                    })
                    .map((info, i) => (
                      <div key={i}>
                        <Hidden smDown>
                          <p
                            className="text-opciones animate__fadeInDownBig"
                            onClick={() => seleccionarAñoTablet(info)}
                            key={info}
                          >
                            {info}
                          </p>
                        </Hidden>
                        <Hidden smUp>
                          <p
                            className="text-opciones animate__fadeInDownBig"
                            onClick={() => seleccionarAñoCelular(info)}
                            key={info}
                          >
                            {info}
                          </p>
                        </Hidden>
                      </div>
                    ))}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </Hidden>
    </>
  );
};
