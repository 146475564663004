import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendKmsRedux, startAcumuladorGNC } from "../../actions/pasosCotizar";
import { BotonContinuarTablet } from "../ButtonContinuar/BotonContinuarTablet";
import Hidden from "@mui/material/Hidden";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

export const Kilometros = () => {
  const { acumulador, valorKms } = useSelector((state) => state.inputReducer);

  const dispatch = useDispatch();

  const [mostrarSelect, setmostrarSelect] = useState(false);

  const mostrarOpciones = () => {
    setmostrarSelect(!mostrarSelect);
  };

  const handleChange = (event) => {
    dispatch(startAcumuladorGNC(event.target.value));
  };

  const handleInputChange = (e) => {
    dispatch(sendKmsRedux(Number(e.target.value)));
  };

  return (
    <div className="container-input-scroll">
      {acumulador !== undefined &&
      acumulador.version !== undefined &&
      acumulador.version !== "" ? (
        <>
          {valorKms !== undefined && valorKms !== "" ? (
            <>
              <Hidden mdDown>
                <div
                  className="btn-hecho animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  {valorKms} kms
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  className="btn-hecho animate__fadeIn"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  {valorKms} kms
                </div>
              </Hidden>
            </>
          ) : (
            <>
              <Hidden mdDown>
                <div
                  className="btn-activo animate__fadeIn"
                  onClick={mostrarOpciones}
                >
                  Kilometros
                </div>
              </Hidden>
              <Hidden mdUp>
                <div
                  className="btn-activo animate__fadeIn"
                  style={{ width: "160px", height: "33px", fontSize: "16px" }}
                  onClick={mostrarOpciones}
                >
                  Kilometros
                </div>
              </Hidden>
            </>
          )}
        </>
      ) : (
        <>
          <Hidden mdDown>
            <div className="btn-disabled animate__fadeIn">Kilometros</div>
          </Hidden>
          <Hidden mdUp>
            <div
              className="btn-disabled animate__fadeIn"
              style={{ width: "160px", height: "33px", fontSize: "16px" }}
            >
              Kilometros
            </div>
          </Hidden>
        </>
      )}
      {mostrarSelect ||
      (acumulador !== undefined &&
        acumulador.version !== undefined &&
        acumulador.version !== "") ? (
        <>
          <Hidden mdDown>
            <div
              className="input-kilometros"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "auto",
              }}
            >
              <input
                type="number"
                onChange={handleInputChange}
                placeholder="Ej: 24000"
                style={{ marginTop: "15px", marginBottom: "15px" }}
              />
              <p className="labelModalManual">¿Tiene o tuvo GNC?</p>
              <Grid style={{ margin: "0" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  onChange={handleChange}
                >
                  <Grid>
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                      defaultChecked
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                </RadioGroup>
              </Grid>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className="input-kilometros-tablet">
              <input
                type="number"
                onChange={handleInputChange}
                placeholder="Ej: 24000"
              />
              <p
                className="labelModalManual"
                style={{ fontSize: "12px", marginTop: "10px" }}
              >
                ¿Tiene o tuvo GNC?
              </p>
              <Grid>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  onChange={handleChange}
                >
                  <Grid container alignItems="center" justifyContent="center">
                    <FormControlLabel
                      value="Si"
                      control={<Radio size="small" />}
                      label="Si"
                      defaultChecked
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </Grid>
                </RadioGroup>
              </Grid>
              <BotonContinuarTablet />
            </div>
          </Hidden>
        </>
      ) : null}
    </div>
  );
};
